import Mail from './mail'
import BannerController from './banner_controller'
import FlyoutController from './flyout_controller'
import { rapidReady, ylkStringToObj } from '../../../../lib/utils/rapid'
import { logError } from '../../../../lib/utils/logger'

const SUBNAV_EXPAND_ICON = '.navicon-chevron-circle'
const NAVRAIL_SUBMENU = '.navrail-submenu'
const HIDE_SUBCHANNELS = 'subChannelsHidden'
const OPEN_SUBCHANNELS = 'open'
const SUBNAV_WRAPPER = '.submenu-wrapper'
const ACTIVECLASS = 'active'
const SUBCHANNEL = '.subitem'
const SEARCH_BOX = '#header-form'
const CLASS_HIDE_SEARCHBAR = 'm-AolHeader--search-hidden'
const CLASS_SEARCH_INTERACTED = 'form-interacted'
const ID_LNAV = 'header-lnav'
const rapidSubChannelConfig = {
  sec: 'lnav',
  slk: 'burger-submenu',
  itc: 1,
  ll2: 'amp-bon'
}
export default class AolHeader {
  constructor({ selector }) {
    this.elem = document.querySelector(selector)
    if (!this.elem) {
      return
    }

    this.Mail = new Mail({
      mailCountSelector: '.m-mail-count',
      mailPreviewSelector: '.m-mail-preview',
      mailPreviewContentSelector: '.m-mail-preview__content'
    })
    this.banner = new BannerController({ parentNode: this.elem })
    this.flyoutController = new FlyoutController({ parentNode: this.elem })

    const headerForm = this.elem.querySelector(SEARCH_BOX)
    this.desktoplnav = document.getElementById(ID_LNAV)

    // hide search box when scrolled down and show when scrolled up again on mobile sizes
    window.addEventListener('scroll', () => {
      if (window.innerWidth < 900) {
        const currentScrollPos = window.pageYOffset
        if (currentScrollPos === 0) {
          headerForm && this.elem.classList.remove(CLASS_HIDE_SEARCHBAR)
        } else {
          headerForm && this.elem.classList.add(CLASS_HIDE_SEARCHBAR)
        }
      }
    })

    window.addEventListener('resize', () => {
      if (window.innerWidth > 900) {
        headerForm && this.elem.classList.remove(CLASS_HIDE_SEARCHBAR)
      }
    })
    ;[...this.elem.querySelectorAll(SUBNAV_WRAPPER)].forEach(subChannelWrap => {
      const navrailSubmenu = subChannelWrap.querySelector(NAVRAIL_SUBMENU)
      const expandIcon = subChannelWrap.querySelector(SUBNAV_EXPAND_ICON)
      const subChannel = navrailSubmenu.querySelector(SUBCHANNEL)
      subChannelWrap.querySelector(SUBNAV_EXPAND_ICON).addEventListener('click', () => {
        expandIcon.classList.toggle(OPEN_SUBCHANNELS)
        navrailSubmenu.classList.toggle(HIDE_SUBCHANNELS)
        rapidSubChannelConfig.elm = navrailSubmenu.classList.contains('subChannelsHidden')
          ? 'close'
          : 'expand'
        this.menuOpenRapidEvent(expandIcon, { ...rapidSubChannelConfig })
      })

      window.addEventListener(
        'resize',
        this.debounce(function() {
          this.resizeEvent(navrailSubmenu, subChannel, subChannelWrap)
        }, 200)
      )
    })

    window.wafer?.base?.addObserver('aolHeaderSearchAutoComplete', () => {
      this.setFormInteracted(headerForm)
      const form = document.getElementById('header-form')
      ;[].forEach.call(form.querySelectorAll('input[data-assist-only'), input => {
        input.disabled = false
      })
      ;[].forEach.call(form.querySelectorAll('input[data-submit-only'), input => {
        input.disabled = true
      })
      this.triggerRapidBeaconClick(form, { elmt: 'srch-assist' })
      const searchValue = headerForm?.q?.value?.trim() || ''
      if (searchValue.length) {
        form.submit()
      } else {
        window.location.reload()
      }
    })

    headerForm?.addEventListener('submit', e => {
      const searchValue = headerForm?.q?.value?.trim() || ''
      if (!searchValue.length) {
        e.preventDefault()
        e.stopPropagation()
        window.location.reload()
      }
    })

    headerForm?.addEventListener('keyup', e => {
      this.setFormInteracted(headerForm)
      if (e.target !== headerForm.q) return
      if (e.key === 'Enter') {
        this.triggerRapidBeaconClick(headerForm, { elmt: 'kybrd' })
      } else {
        const {
          q: { value = '' }
        } = headerForm
        value !== '' && this.rapidFirstCharBeacon(headerForm)
      }
    })

    headerForm?.addEventListener('mouseup', e => {
      this.setFormInteracted(headerForm)
    })

    headerForm?.submitIcon.addEventListener('mouseup', e => {
      this.triggerRapidBeaconClick(headerForm, { elmt: 'icon' })
    })

    window.addEventListener(
      'resize',
      this.debounce(() => {
        // Refresh Rapid for visibility changes;
        this.refreshRapid(this.desktoplnav)
      }, 200)
    )

    if (this.elem.classList.contains('shopping')) {
      this.initNCIDForwarder(selector)
    }
  }

  rapidFirstCharBeacon(form) {
    if (!this._hasFiredFirstCharBeacon) {
      this.triggerRapidBeaconClick(form, { elmt: 'keystroke', itc: 1 })
      this._hasFiredFirstCharBeacon = true
    }
  }

  triggerRapidBeaconClick(form, additionalYlk = {}) {
    const action = form.getAttribute('action')
    const ylk = this.parseYlk(
      [form, form.querySelector('#header-form-search-button')],
      additionalYlk
    )
    const params = this.getInputsAsParams(form)
    ylk.tar_uri = `${action}?${params}`
    rapidReady(rapid => {
      rapid.beaconClick(ylk.sec, ylk.slk, '1', ylk)
    })
  }

  parseYlk(elements = [], additionalYlk = {}) {
    const ylk = {}
    elements.forEach(el => {
      if (!el) {
        return
      }
      const attr = el.getAttribute('data-ylk')
      if (attr) {
        attr.split(';').forEach(part => {
          const parts = part.split(':')
          ylk[parts[0]] = parts[1]
        })
      }
    })
    return { ...ylk, ...additionalYlk }
  }

  getInputsAsParams(form) {
    const params = []
    ;[].forEach.call(form.elements, input => {
      if (!input.disabled && input.name && input.value) {
        params.push(`${input.name}=${encodeURIComponent(input.value)}`)
      }
    })
    return params.join('&')
  }

  debounce(fn, wait, immediate) {
    let timeout
    return (...args) => {
      const context = this
      const later = () => {
        timeout = null
        if (!immediate) fn.apply(context, args)
      }
      const callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait || 150)
      if (callNow) fn.apply(context, args)
    }
  }

  resizeEvent(navrailSubmenu, subChannel, subChannelWrap) {
    if (
      window.innerWidth < 900 &&
      !navrailSubmenu.classList.contains(HIDE_SUBCHANNELS) &&
      subChannel
    ) {
      navrailSubmenu.classList.add(HIDE_SUBCHANNELS)
      subChannel.classList.remove(ACTIVECLASS)
    }
    if (window.innerWidth > 1265 && navrailSubmenu.classList.contains(HIDE_SUBCHANNELS)) {
      if (subChannelWrap.dataset.channel === subChannelWrap.dataset.parentchannel) {
        navrailSubmenu.classList.remove(HIDE_SUBCHANNELS)
        if (subChannel && subChannel.dataset.subChannel === subChannel.dataset.channeltitle)
          subChannel.classList.add(ACTIVECLASS)
      }
    }
  }

  refreshRapid(elem) {
    rapidReady(rapid => {
      if (!elem?.id) return
      if (rapid.isModuleTracked(elem?.id)) {
        rapid.refreshModule(elem?.id)
      } else {
        rapid.addModulesWithViewability([elem?.id])
      }
    })
  }

  menuOpenRapidEvent(menu, overrides = {}) {
    const { ylk = '' } = menu.dataset
    const ylkObject = { ...ylkStringToObj(ylk), ...overrides }
    rapidReady(rapid => {
      rapid.beaconClick(ylkObject.sec, ylkObject.slk, ylkObject.pos, ylkObject)
    })
  }

  // This is to ensure focus styles only get added after first interaction and not on page load focus
  setFormInteracted(form) {
    if (form?.classList.contains(CLASS_SEARCH_INTERACTED)) return
    form?.classList.add(CLASS_SEARCH_INTERACTED)
  }

  initNCIDForwarder(selector) {
    const { ncidForwarder } = window.NEXUtils || {}
    if (!ncidForwarder) {
      logError('Header', 'NCID Forwarder not found')
      return
    }

    ncidForwarder.addTrackedLinksSelector(`${selector} a`)
  }
}
